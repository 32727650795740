import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import SidePanel from '../sidepanel/SidePanel';
import useLocales from '../../hooks/useLocales';
import { DeleteRounded, EmailRounded } from '@mui/icons-material';

type Props = {
  onSubmit: () => void,
  icon?: 'delete' | 'email', // Add types as neeed. Remember to add config to getIcon
  buttonText?: string,
  confirmButtonText?: string,
  warningText?: string,
  showLoader?: boolean,
  shouldClose?: boolean;
}

export default function ConfirmableButton(props: Props) {

  const { t } = useLocales();

  const [state, setState] = useState<boolean>(false);

  useEffect(() => {
    if (props.shouldClose === true) {
      setState(false);
    }
  }, [props.shouldClose]);

  const getIcon = (type?: string) => {
    switch (type) {
      case 'email':
        return <EmailRounded />;
      case 'delete':
      default:
        return <DeleteRounded />;
    }
  };

  return (
    <Box>
      <SidePanel
        open={ state }
        close={() => setState(false)}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="h6">{ props.warningText || t('Are you sure?')}</Typography>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Button onClick={() => setState(false) }>{t('Cancel')}</Button>
            {
              props.showLoader ? <CircularProgress /> :
                <Button variant="contained" color="primary" onClick={props.onSubmit}>{ props.confirmButtonText || t('Confirm') }</Button>
            }

          </Box>
        </Box>
      </SidePanel>
      {
        props.buttonText ? <Button variant="contained" color="secondary" onClick={() => setState(true) } startIcon={ getIcon(props.icon) }>{ props.buttonText}</Button> :
        <IconButton onClick={() => setState(true) }>{ getIcon(props.icon) }</IconButton>
      }

    </Box>
  );
}