import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiRequest } from '../../helpers/apiHelper';
import { RootState } from '../../store';
import { DocumentType } from '../../types/documents';

export interface DocumentsState {
  list: DocumentType[];
  loading: boolean;
  loaded: boolean;
  error: string | null;
  saving: boolean;
  saved: boolean;
  reload: boolean;
  deleting: boolean;
  deleted: boolean;
}

const fakeData = [{ id: 1, description: 'Kuitti 1', total: 10, date: new Date() }];

const initialState: DocumentsState = {
  list: [],
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
  error: null,
  reload: false,
  deleting: false,
  deleted: false,
};


export const getDocuments = createAsyncThunk<DocumentType[]>('documents/get', async (_: any, thunkApi: any) => {
  return await apiRequest({ path: '/document', thunkApi, method: 'GET' });
});

export const addDocument = createAsyncThunk<void, Partial<DocumentType>>('documents/add', async (data: Partial<DocumentType>, thunkApi: any) => {
  return await apiRequest({ path: '/document', data, thunkApi, method: 'POST' });
});
export const updateDocument = createAsyncThunk('documents/update', async (params: { id: number, data: Partial<DocumentType>}, thunkApi: any) => {
  return await apiRequest({ path: `/document/${params.id}`, data: params.data, thunkApi, method: 'PATCH' });
});

export const deleteDocumentAction = createAsyncThunk('documents/delete', async (id: number, thunkApi: any) => {
  return await apiRequest({ path: `/document/${id}`, thunkApi, method: 'DELETE' });
});

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clear: (state) => {
      state.list = [];
      state.loaded = false;
      state.reload = true;
      state.saved = false;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.

  extraReducers: (builder) => {
    builder.addCase(getDocuments.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.loaded = false;
    });

    builder.addCase(getDocuments.fulfilled, (state, action: PayloadAction<DocumentType[]>) => {
      state.loading = false;
      state.loaded = true;
      state.list = action.payload;
    });

    builder.addCase(getDocuments.rejected, (state, action: PayloadAction<any>) => {
      console.error('Error getting documents', action);
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    });

    builder.addCase(addDocument.pending, (state, _: any) => {
      state.saving = true;
    });
    builder.addCase(addDocument.fulfilled, (state, _: any) => {
      state.saving = false;
      state.saved = true;
      state.reload = true;
      state.loaded = false;
    });
    builder.addCase(addDocument.rejected, (state, action: PayloadAction<any>) => {
      console.error('Error adding document', action);
      state.saving = false;
      state.saved = false;
      state.error = action.payload;
    });

    builder.addCase(updateDocument.pending, (state, _: any) => {
      state.saving = true;
    });
    builder.addCase(updateDocument.fulfilled, (state, _: any) => {
      state.saving = false;
      state.saved = true;
      state.reload = true;
      state.loaded = false;
    });
    builder.addCase(updateDocument.rejected, (state, action: PayloadAction<any>) => {
      console.error('Error updating document', action);
      state.saving = false;
      state.saved = false;
      state.error = action.payload;
    });

    builder.addCase(deleteDocumentAction.pending, (state) => {
      state.deleting = true;
      state.deleted = false;
    });

    builder.addCase(deleteDocumentAction.fulfilled, (state) => {
      state.deleting = false;
      state.deleted = true;
      state.loaded = false;
      state.reload = true;
    });

    builder.addCase(deleteDocumentAction.rejected, (state) => {
      state.deleting = false;
      state.deleted = false;
    });
  }
});

export const selectDocuments = (state: RootState) => state.documents.list;

export const { clear: clearDocumentState } = documentsSlice.actions;

export default documentsSlice.reducer;
